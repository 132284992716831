<template>
    <div v-if="filterDataSource" class="p-relative">

        <Toolbar
            :is-list="true"
            v-on:toolbar-button-click="onToolbarClick"
        >
            <!--Панель поиска-->
            <SearchPanel
                v-model="search"
                :has-filter="false"
            />
        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
            v-bind:substatus.sync="status"
        />

        <component
            :is="collection"
            :status="status"
            :search="search"
            ref="currentComponent"
        />

    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { httpAPI } from "@/api/httpAPI";

import Toolbar from '@/components/documents/Toolbar.vue';
import SearchPanel from '@/components/documents/SearchPanel.vue';
import FilterPanel from '@/components/documents/FilterPanel.vue';

import OnAdjustment from './InWorkLists/OnAdjustment.vue';
import OnCheck from './InWorkLists/OnCheck.vue';
import OnExecution from './InWorkLists/OnExecution.vue';
import OnInspect from './InWorkLists/OnInspect.vue';
import OnSign from './InWorkLists/OnSign.vue';
import OnRework from './InWorkLists/OnRework.vue';
import OnControl from './InWorkLists/OnControl.vue';
import OnInspectResolution from './InWorkLists/OnInspectResolution.vue';

export default {
    name: "InWorkProxy",
    components: {
        Toolbar,
        SearchPanel,
        FilterPanel,
        "Documents.My.OnAdjustment": OnAdjustment,
        "Documents.My.OnCheck": OnCheck,
        "Documents.My.OnExecution": OnExecution,
        "Documents.My.OnInspect": OnInspect,
        "Documents.My.OnSign": OnSign,
        "Documents.My.OnRework": OnRework,
        "Documents.My.OnExecuterControl": OnControl,
        "Documents.My.OnInspect.Resolution": OnInspectResolution,
    },
    computed: {
        collection: {
            get: function() {
                return this.$store.getters['correspondence/inWork/getCollection'];
            },
            set: function(newValue) {
                this.$store.commit('correspondence/inWork/SET_COLLECTION', newValue);
            }
        },
        status: {
            get: function() {
                return this.$store.getters['correspondence/inWork/getStatus'];
            },
            set: function(newValue) {
                if (typeof this.$refs.currentComponent?.resetPage === "function")
                    this.$refs.currentComponent.resetPage();

                this.$store.commit('correspondence/inWork/SET_STATUS', newValue);
            }
        },
        search: {
            get: function() {
                var searches = this.$store.getters['correspondence/inWork/getSearches'];

                if (!searches) return "";
                
                return searches.find(x => x.collection == this.collection)?.value ?? "";
            },
            set: _.debounce(function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function")
                    this.$refs.currentComponent.resetPage();

                this.$store.commit('correspondence/inWork/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        }
    },
    data () {
        return {
            filterDataSource: null
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        onToolbarClick(event, btn) {
            this[btn.Action]();
        },
        async loadCollectionData () {
            this.$eventBus.$emit('update_correspondents_counters');
            let response = await httpAPI({
                url: `api/actions/collection?type=${this.collection}`,
                method: 'GET'
            });

            if (response) {
                await this.loadFilters();
                this.$store.commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu });
            }
        },
        async loadFilters() {
            let filterResponse = await httpAPI({
                url: `/api/correspondence/filter?collection=InWork`,
                method: 'GET'
            });

            if (filterResponse) {
                var permissions = (this.$store.getters['global/auth/getUserInfo'])?.Permissions ?? [];
                var filter = filterResponse.data.Payload.Filter.Items.find(x => x.Value == "InWork");

                if (filter && filter.Items) {
                    filter.Items.Items = filter.Items.Items?.filter(i => this.$_.difference(i.requires, permissions).length === 0);
                    this.filterDataSource = { Filter: filter.Items };
                }
            }
        },
        async Refresh() {
            await this.loadFilters();
            this.$refs.currentComponent.getData(true);
        }
    },
    watch: {
        collection : {
            handler () {
                this.loadCollectionData();
            }
        }
    },
    async created () {
        this.setOverlayVisible({ visible: true });
        try
        {
            await this.loadCollectionData();
        }
        finally
        {
            this.setOverlayVisible({ visible: false });
        }
    }
}
</script>