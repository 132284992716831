<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                :item-class="itemRowBackground"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents|CitizenStatements.Document', common: data.item.iscommon })">
                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                <template v-slot:[`item.regdate`]="{ item }">
                    {{ formatDate(item.regdate) }}
                </template>

                <template v-slot:[`item.correspondent`]="{ item }">
                    <div class="etw-text-container">
                        <div class="etw-text" v-tooltip.top-center="item.correspondent">
                            {{item.correspondent}}
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.theme`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <v-doc-type-chip v-if="item.issubstitution" :type="'Substitution'" :text="$t('Замещение')"/>
                        <v-common-chip v-if="$helpers.getCommonStatusForList(item)" :document-type="item.doctypeof"/>
                        <v-doc-type-chip :type="item.doctypeof" :text="item.doctype"/>
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.theme">
                                {{item.theme}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.controldate`]="{ item }">
                    {{ formatDate(item.controldate) }}
                </template>

                <template v-slot:[`item.controldays`]="{ item }">
                    <StatusChip :status="avaibleStatuses[$helpers.getStatusByDays(item.controldays)]"/>
                </template>

                <template v-slot:[`footer.prepend`]>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>
        </v-col>

    </v-row>

</template>

<script>
import { httpAPI } from "@/api/httpAPI";
import { mapActions } from 'vuex';

import StatusChip from '@/components/global/chips/StatusChip';

export default {
    columnIndex:  {
        'regnumber': 4,
        'regdate': 5,
        'correspondent': 6,
        'theme': 7,
        'controldate': 8
    },
    props: {
        status: {
            type: String,
            default: null
        },
        search: {
            type: String,
            default: ""
        }
    },
    components: {
        StatusChip
    },
    computed: {
        headers() {
            return [
                { text: this.$t("Номер"), value: 'regnumber', width: '150px'  },
                { text: this.$t("Рег._дата"), value: 'regdate', width: '110px'  },
                { text: this.$t("Корреспондент"), value: 'correspondent', width: '20%' },
                { text: this.$t("Краткое_содержание"), value: 'theme' },
                { text: this.$t("Срок"), value: 'controldate', width: '110px'  },
                { text: this.$t("Статус"), value: 'controldays', sortable: false, width: '150px'  }
            ];
        },
        options: {
            get: function() {
                return this.$store.getters['correspondence/inWork/onExecution/getTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('correspondence/inWork/onExecution/SET_TABLE_OPTION', newValue);
            }
        },
        collection () {
            return this.$store.getters['correspondence/inWork/getCollection'];
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search,
                ExecutionStatus: this.status
            }
        },
        avaibleStatuses() {
            return this.$store.getters['documents/references/GetAvaibleStatuses'];
        }
    },
    data () {
        return {
            loading: true,
            total: 0,            
            entities: [],

            prevTableFilterObject: null,
            paginationPageCount: 0,
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        itemRowBackground(item) {
            return item.readed ? '' : 'font-weight-bold';
        },
        resetPage() {
            this.$store.commit('correspondence/inWork/onExecution/SET_TABLE_OPTION_PAGE', 1);
        },
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;

            this.setOverlayVisible({ visible: true });
            this.prevTableFilterObject = this.getTableFilterObject();

            this.total = 0;
            this.entities = [];
            this.loading = true;

            let response = await httpAPI({
                url: `/api/correspondence/inwork/list?type=MyDocuments.OnExecution&filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET'
            });

            this.entities = response?.data?.Payload?.data ?? [];
            this.total = response?.data?.Payload?.total ?? 0;

            this.loading = false;
            this.setOverlayVisible({ visible: false });
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, ExecutionStatus } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "regdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 5;
            return { ExecutionStatus, SearchStr, OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage };
        },
        formatDate(value) {
            if (!value)
                return '';

            return this.$moment(value).format('DD.MM.YYYY');
        }
    },
    watch: {
        filter: {
            handler() {
                this.getData()
            },
            deep: true,
        },
    }
}
</script>